<template>
  <div id="realdata">
    <div class="mbody">
      <div class="bc1">1233445</div>
      <div class="triangle"></div>
      <div class="mcon">
        <p>
          <img src="../../../../assets/images/time.png" />
          <span class="date" id="date"></span>
          <span class="time" id="time"></span>
        </p>
        <div class="block"></div>
        <div class="ts">
          <table>
            <tr>
              <td>实时数据</td>
            </tr>
          </table>
        </div>
        <div class="block"></div>
        <div
          style="
            height: 4px;
            border-top: 1px solid #ffffff;
            margin: 0 auto;
            width: 95%;
          "
        ></div>
      </div>
      <div class="bc2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vtourIndex",
  data() {
    return {};
  },
};
</script>

<style scoped>
.mbody {
  width: 100%;
  height: 100%;
  position: relative;
}

p {
  text-align: center;
  height: 34px;
  line-height: 34px;
  /*width: 100%;*/
  margin: auto;
}

p img {
  width: 26px;
  height: 26px;
  vertical-align: middle;
}

p span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  font-size: 1.7rem;
  letter-spacing: 1px;
}

.ts {
  width: 100%;
  /*height:126px;*/
  padding: 0 10px;
}

table {
  text-align: center;
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
}

table tr td {
  height: 40px;
  line-height: 40px;
  font-size: 1.75rem;
  min-width: 75px;
}

.block {
  width: 100%;
  height: 10px;
}

.triangle {
  height: 0px;
  /*background: -webkit-linear-gradient(left,#019dd8,#0297e3); !* Safari 5.1 - 6.0 *!
        background: -o-linear-gradient(left,#019dd8,#0297e3); !* Opera 11.1 - 12.0 *!
        background: -moz-linear-gradient(left,#019dd8,#0297e3); !* Firefox 3.6 - 15 *!
        background: linear-gradient(left,#019dd8,#0297e3);*/
  /*background-color:#019dd8;*/
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 16px solid transparent;
  border-top: 16px solid rgba(34, 142, 214, 0.8);
}
.mcon {
  width: 100%;
  height: 100%;
  background: rgba(2, 136, 228, 0.5);
  border-bottom: 1px solid #ffffff;
}
.bc1 {
  width: 100%;
  height: 0;
  border-left: 26px solid transparent;
  border-bottom: 26px solid rgba(2, 136, 228, 0.5);
  letter-spacing: 1px;
  font-size: 1.52rem;
  padding-left: 5px;
  line-height: 26px;
  box-sizing: border-box;
}
.bc2 {
  width: 100%;
  height: 0;
  border-right: 26px solid transparent;
  border-top: 26px solid rgba(0, 148, 220, 0.8);
  box-sizing: border-box;
}
</style>